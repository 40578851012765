p.loginWarning {
  text-align: center;
  margin-top: 3em;
  margin-bottom: 2em;
}

#loginButton{
  border: none;
  background: none;
  cursor: pointer;
  display: block;
  margin: auto;
}