form {
  display: flex;
  flex-direction: column;
  width: 500px;

  @media only screen and (max-width: 720px) {
    width: auto;
  }
}

p {
  display: flex;
  flex-direction: column;
}

fieldset {
  border: none;
  display: flex;
  flex-direction: column;
  margin-top: 2em;

  legend {
    font-size: 1.5em;
    flex: 1;
  }
}

select, input {
  font-size: 1.25em;
  padding: 0.25em 0.5em;
}

.topSpace {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
}

textarea {
  min-height: 75px;
}
