.prijzen {
  td {
    // white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap:nowrap;

    * {
      display: flex;
      flex: 1;
    }

    span {
      padding: 0 0.5em 0 0;
      text-align: right;
    }

    input {
      width: 5em;
    }
  }
}