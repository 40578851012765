header {
  background-color: #FFD700;
  padding: 0.5em;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 720px) {
    flex-direction: column;
  }

  .brand {
    display: flex;
    flex-direction: row;
    

    img {
      height: 50px;
      flex: 0;
    }

    > div {
      margin-left: 1em;
      padding-top: 0.5em;

      h1, h2 {
        margin: 0;
        font-weight: normal;
      }

      h1 {
        font-size: 1em;
        font-weight: bold;
      }

      h2 {
        font-size: 0.8em;
        padding-top: 0.2em;
      }
    }
  }

  nav {
    display: flex;
    justify-content: flex-end;
    flex: 1;

    @media only screen and (max-width: 720px) {
      justify-content: center;
      margin: 1em 0 0.5em 0;
    }

    ul {
      display: flex;
      flex-direction: row;
      list-style: none;
      padding: 0;
      margin: 0;

      @media only screen and (max-width: 720px) {
        flex-direction: column;

        li {
          align-items: center;
        }
      }

      li {
        white-space: nowrap;
        display: flex;
        align-items: stretch;

        a {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.5em;
          text-decoration: none;
          color: #000;

          &:hover {
            background-color: lighten($color: #FFD700, $amount: 20%);
          }
        }
      }
    }
  }
}
