.dropzone {
  height: 250px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #eee;
  cursor: pointer;
  color: #888;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0% 20%;

    img {
      width: 30%;
      margin-bottom: 1em;
    }

    ul {
      text-align: left;
    }
  }
}