ul.adminHeader {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0;
  padding: 0;

  @media only screen and (max-width: 720px) {
    flex-direction: column;
  }

  li {
    padding: 0.4em;

    a {
      text-decoration: none;
    }
  }
}