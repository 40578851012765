.klasSelector {
  display: flex;
  flex-direction: column;

  input {
    font-size: 1.25em;
    padding: 0.25em;
  }

  .klasLijst {
    border: 1px solid #ccc;
    border-top: none;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    label {
      padding: 0.25em;

      &:hover {
        background-color: #eee;
      }
    }
  }
}