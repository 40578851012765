table {
  td, th {
    padding: 0.25em 0.5em;
    margin: 0;
    text-align: left;
  }

  thead {
    th {
      border-bottom: 2px solid #ddd;
    }
  }

  tbody {

    tr:nth-child(even) {
      background-color: #eee;
    }
  }

  a {
    text-decoration: none;
  }

  td.numeriek {
    text-align: right;
  }
}