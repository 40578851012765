html, body, #root {
  margin: 0;
  font-family: sans-serif;
  height: 100%;
}

button {
  margin: 0.5em;
  padding: 0.5em;
  font-size: 1.25em;
}