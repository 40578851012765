section {
  display: flex;
  flex-direction: column;
  
  main {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 720px) {
      align-items: stretch;
    }
  }
}