img.loader {
  animation-name: spin;
  animation-duration: 750ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  height: 100px;
  width: 100px;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% { transform: rotate(-45deg); }
  100% { transform: rotate(315deg); }
}

.loaderDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
