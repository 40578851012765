.spin {
  animation-name: spin;
  animation-duration: 750ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  0% { transform: rotate(0deg) perspective(0); }
  100% { transform: rotate(360deg) perspective(0); }
}